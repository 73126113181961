import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {mediaBaseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";

function Banner({data}) {
    return (

        <div className="services_banner ">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={7} lg={7} xl={7} xxl={7} className="my-auto">
                        <div className="example" data-text="Tek Podcast">
                            <h1>{HtmlParser(data?.title)}</h1>
                        </div>
                        <div
                            className="banner_content_txt listing_flex">{HtmlParser(data?.short_description)}</div>
                    </Col>
                    <Col xs={12} md={5} lg={5} xl={5} xxl={5} className="my-auto text-center">
                        <img
                            src={`${mediaBaseURL}${data?.banner_img}`}
                            className="img-fluid"
                            alt={data?.title}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Banner;